.instagram-grid{
  border: none;
  width: 100%;
  height: 750px;
  cursor: pointer;
}
@media (min-width: 1400px){
  .instagram-grid{
    height: 860px;
  }
}