.footer{
  background-color: var(--verde);
  padding: 25px 0;
  color: var(--blanco);
}
.red-social{
  text-decoration: none;
  color: var(--blanco);
}
.red-social:hover{
  color:var(--amarillo);
}