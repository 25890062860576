.tromen-card{
  border-bottom: 5px solid var(--rojo);
  width: 30%;
  overflow: hidden;
  height: 500px;
  position: relative;
}
.tromen-card-img{
  width: 280px;
  height:280px;
  object-fit: contain;
  filter: brightness(1.1);
  mix-blend-mode: multiply;
  cursor: zoom-in;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: zoom-out;
}

.expanded-img {
  max-width: 90%;
  max-height: 90%;
}
@media (min-width:573px) and (max-width:1022px){
  .tromen-card{
    border-bottom: 0;
    border-left: 5px solid var(--rojo);
    width: 48%;
  }
}
@media (max-width:572px){
  .tromen-card{
    border-bottom: 0;
    border-left: 5px solid var(--rojo);
    width: 100%;
  }
}
