.boton-mas{
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  font-size: 14px;
}
.boton-mas:hover{
  opacity: 0.8;
}
@media (max-width: 1000px) {
  .boton-mas{
    right: 15px;
  }
}