.tractores-card{
  border-bottom: 5px solid var(--amarillo);
  width: 32%;
  overflow: hidden;
  position: relative;
}
.tractores-card-img{
  width: 250px;
  cursor: zoom-in; 
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: zoom-out;
}

.expanded-img {
  max-width: 90%;
  max-height: 90%;
}
@media (min-width:573px) and (max-width:1022px){
  .tractores-card{
    border-bottom: 0;
    border-left: 5px solid var(--amarillo);
    padding: 0 10px;
    width: 100%;
  }
  .tractores-card .card-body{
    text-align: left;
    padding-left: 25px;
  }
}
@media (max-width:572px){
  .tractores-card{
    border-bottom: 0;
    border-left: 5px solid var(--amarillo);
    width: 100%;
  }
}