.contact-img{
  width: 250px;
  height: 95px;
  margin-bottom: 15px;
}
.contact-box{
  text-align: center;
}
@media (max-width:1022px){
  .contact-box{
    text-align: center;
  }
}