.detalles-hero{
  width: 100%;
  height: 50vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.detalles-hero-img{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.detalles-hero-title{
  position: absolute;
  font-weight: 800;
  font-size: 80px;
  bottom: 0;
  left: 15px;
  color: var(--blanco);
}

@media (min-width:573px) and (max-width:1024px){
  .detalles-hero{
    height: 40vh;

  }
  .detalles-hero-title{
    font-size: 60px;
  }
  
}

@media (max-width:573px){
  .detalles-hero{
    height: 40vh;
  }
  .detalles-hero-img{
    height: 100%;
  }
  .detalles-hero-title{
    font-size: 40px;
  }
  
}