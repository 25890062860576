.tractor-linea-box{
  width: 100%;
  height: 160px;
  background-color: var(--gris-claro);
  border-bottom: 15px solid var(--amarillo);
  border-radius: 5px;
}
.logo-linea-tractores{
  width: 350px;
}
@media (max-width: 572px){
  .tractor-linea-box{
    height: 130px;
  }
  .logo-linea-tractores{
    width: 80%;
  }
}