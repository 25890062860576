.hero-seccion{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.img-hero-seccion{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.logo-seccion{
  position: absolute;
  max-width: 600px;
}
.text-hero-seccion{
  position: absolute;
  font-size: 120px;
  font-weight: bolder;
  color: var(--naranja);
  text-align: center;
}
@media (max-width: 1024px){
  .logo-seccion{
    width: 70%;
  }
}
@media (max-width:573px){
  .text-hero-seccion{
    font-size: 75px;
  }
}