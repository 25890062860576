.error{
  width: 100%;
  height: 100vh;
}
.error-link{
  text-decoration: none;
  color: var(--verde);
  font-weight: bold;
}
.error-link:hover{
  color: var(--verde);
  opacity: 0.8;
}
.error-logo{
  width: 250px;
}
.error-vial{
  width: 100%;
}
.esquina-1{
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 0;
}
.esquina-2{
  position: fixed;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
  width: 50%;
  z-index: 0;
}
.text{
  z-index: 1;
}