.semilla-titulo{
  font-size: 48px;
  font-weight: bold;
  color: var(--verde);
}
.semilla-sutitulo{
  font-size: 40px;
  font-weight: bold;
}
.maiz{
  color: var(--naranja);
}
.sorgo{
  color: var(--rojo);
}
.soja{
  color: rgb(84, 84, 255);
}
.accordion-semillas{
  font-size: 35px;
  font-weight: bold;
  padding: 15px 20px;
}
.accordion-semillas:not(.collapsed).maiz {
  color: var(--naranja);
}

.accordion-semillas:not(.collapsed).sorgo {
  color: var(--rojo);
}

.accordion-semillas:not(.collapsed).soja {
  color: rgb(84, 84, 255);
}

.accordion-semillas:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.accordion-semillas:focus{
  box-shadow: none;
}
@media (min-width:573px) and (max-width:1022px){
  .accordion-semillas{
    font-size: 37px;
  }
  .accordion-semillas:not(.collapsed){
    font-size: 37px;
  }
}
@media (max-width:572px){
  .accordion-semillas{
    font-size: 25px;
  }
  .accordion-semillas:not(.collapsed){
    font-size: 25px;
  }
  .accordion-semillas{
    background-color: transparent
  }
}